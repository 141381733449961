<template>
  <div class="flex flex-col">
    <h2 class="text-theme-sidebar font-semibold font-poppins text-2xl">
      {{ $t("receiptSendCard.header") }}
    </h2>
    <b-card>
      <b-card-text>
        <b-container class="mb-3">
          <ValidationObserver ref="formReceipt">
            <b-row>
              <b-col cols="12" lg="4">
                <div class="flex flex-col">
                  <div class="mb-3">
                    <label for="" class="text-base">Kunder</label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                      <span
                          v-for="(item, i) in selectedCustomer"
                          :key="item.uuid"
                          class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                      >
                        <small>Kund #: {{ item.name }}</small>
                        <span class="cursor-pointer" @click="removeCustomer(i)">
                          <feather-icon icon="Trash2Icon"/>
                        </span>
                      </span>
                    </div>
                    <div>
                      <v-select
                          class="w-full"
                          v-model="emptyCate"
                          label="document_number"
                          @option:selected="setCustomer($event)"
                          :filterable="false"
                          :options="customersList"
                          @search="searchCustomers"
                      >
                        <template slot="no-options">
                          Sök...
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.customer_number }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div>
                    <validation-provider
                        #default="{ errors }"
                        :name="'message'"
                    >
                      <label for="" class="text-base">Meddelande i mejlet</label>
                      <div>
                        <b-form-textarea
                            v-model="messageForm"
                            id="textarea"
                            placeholder="Tack för er betalning!"
                            rows="4"
                            max-rows="8"
                        ></b-form-textarea>
                      </div>
                      <small class="text-danger" v-text="errors[0]"/>
                    </validation-provider>
                  </div>
                </div>
              </b-col>

              <b-col cols="12" lg="4">
                <p class="" v-text="$t(locale.messageTitle)"/>

                <ul class="mb-1 pl-2">
                  <li
                      v-for="(message, index) in messageList"
                      :key="index"
                      style="list-style-type: disc"
                      v-text="message"
                  />
                </ul>
                <p class="" v-text="$t(locale.receiptFooter)"/>
                <br>
              </b-col>
              <b-col cols="12" lg="4" class="md:h-72">
                <div class="md:absolute md:inset-x-0 md:bottom-0">
                  <button
                      @click="sendReceipts"
                      type="submit"
                      class="flex p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md"
                  >
                    <span v-if="sending">
                      <b-spinner small type="grow"/>
                      {{ $t("Message.loading") }}...
                    </span>

                    <span
                        class="whitespace-nowrap text-center w-full text-base"
                        v-else
                        v-text="'Skicka kvitton för valda fakturor/kunder'"
                    />
                  </button>
                </div>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </b-card-text>

      <b-card-title v-text="'Faktura-/kvittodetaljer'"/>
      <div>
        <div class="row">
          <div class="bg-white p-1 full col-md-12">
            <div class="block lg:flex ">
              <div class="w-full lg:w-4/12">
                <v-select
                    class="w-full"
                    label="value"
                    v-model="filterType"
                    @option:selected="onCategorySelect($event)"
                    :filterable="false"
                    :options="categoryList"
                >
                  <template slot="no-options"> Sök...</template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ $t(option.label) }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ $t(option.label) }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="w-full lg:8/12">
                <div class="block lg:flex" v-if="categoryType === 'payment_date_range'">
                  <div class=" lg:ml-2" style="padding-top: 10px">{{ $t('receiptSendCard.paymentDateRange') }}</div>
                  <div class="lg:ml-2">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                      <div class="mr-2">
                        <date-picker
                            valueType="format"
                            v-model="form.payment_date_range.start_date"
                            v-on:change="onChangePaymentDate"
                            class="w-2/3 payout-xmx  receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.startDate')"
                        />
                      </div>
                      <div>
                        <date-picker
                            valueType="format"
                            v-model="form.payment_date_range.end_date"
                            v-on:change="onChangePaymentDate"
                            class="w-2/3 payout-xmx  receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.endDate')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block lg:flex" v-if="categoryType === 'invoice_date_range'">
                  <div class="sm:ml-0 lg:ml-2 mb-0.5 lg:mb-0 lg:mr-2" style="padding-top: 10px">
                    {{ $t('receiptSendCard.invoiceDateRange') }}
                  </div>
                  <div class="ml-0 lg:ml-2">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                      <div class="mr-2 w-full sm:mt-1 lg:mt-0">
                        <date-picker
                            valueType="format"
                            v-model="form.invoice_date_range.start_date"
                            v-on:change="onChangePaymentDate"
                            class="w-full lg:w-2/3 payout-xmx receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.startDate')"
                        />
                      </div>
                      <div class="w-full  sm:mt-1 lg:mt-0">
                        <date-picker
                            valueType="format"
                            v-model="form.invoice_date_range.end_date"
                            v-on:change="onChangePaymentDate"
                            class="w-full lg:w-2/3 payout-xmx receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.endDate')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block lg:flex" v-if="categoryType === 'due_date_range'">
                  <div class="ml-0 lg:ml-2" style="padding-top: 10px">{{ $t('receiptSendCard.dueDateRange') }}</div>
                  <div class="ml-0 lg:ml-2">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
                      <div class="ml-0 lg:mr-2">
                        <date-picker
                            valueType="format"
                            v-model="form.due_date_range.start_date"
                            v-on:change="onChangePaymentDate"
                            class="w-full lg:w-2/3 payout-xmx receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.startDate')"
                        />
                      </div>
                      <div class="ml-0 lg:mr-2">
                        <date-picker
                            valueType="format"
                            v-model="form.due_date_range.end_date"
                            v-on:change="onChangePaymentDate"
                            class="w-full lg:w-2/3 payout-xmx receipt-xms"
                            prefix-class="xmx"
                            :placeholder="$t('paymentCard.endDate')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-card-text>
        <b-table
            responsive
            show-empty
            :busy="loading"
            class="scrol-area"
            :items="items.results"
            :fields="fields"
        >
          <template #head(checkbox)="data">
            <div
                class="d-flex align-items-center justify-content-center"

            >
              <b-form-checkbox
                  v-model="selected"
                  @change="performAllChecked"
                  :value="checked"
                  class="custom-control-primary my-auto"
              />
            </div>
          </template>

          <template #head(invoiceNumber)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(customerNumber)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(customerName)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(invoiceDate)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(finalPayDate)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(modeOfPayments)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(sendTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(sent)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <!-- <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template> -->
          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(has_been_credited)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(is_credit)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #cell(checkbox)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                style="width: 100% !important;"
            >
              <b-form-checkbox
                  @change="performSingleChecked($event,data)"
                  v-model="data.item.checked"
              />
            </div>
          </template>

          <template #cell(invoiceNumber)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.document_number"
              />
            </div>
          </template>

          <template #cell(customerNumber)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.customer_number"
              />
            </div>
          </template>
          <template #cell(customerName)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.customer_name"
              />
            </div>
          </template>

          <template #cell(invoiceDate)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.invoice_date"/>
            </div>
          </template>

          <template #cell(finalPayDate)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.final_pay_date"/>
            </div>
          </template>

          <template #cell(modeOfPayments)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="data.item.mode_of_payments"/>
            </div>
          </template>

          <template #cell(sendTime)="data">
            <div
                v-if="data.item.receipt_sent"
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.receipt_sent_on.split('T')[0]"
              />
            </div>
            <div
                v-else
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="data.item.receipt_sent_on"
              />
            </div>
          </template>

          <template #cell(is_credit)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.is_credit">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>
          <template #cell(has_been_credited)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.has_been_credited">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>


          <template #cell(sent)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.receipt_sent">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center gap-3 justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="cursor-pointer" @click="openModal(data.item)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>
              <span
                  class="cursor-pointer"
                  @click="downloadReceipt(data.item.uuid)"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                  />
                </svg>
              </span>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('Message.loading') }}...</strong>
            </div>
          </template>
        </b-table>

        <div
            class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
        >
          <paginate
              v-model="pagination.currentPage"
              :page-count="pagination.pages"
              :click-handler="firePagination"
              :prev-text="'<'"
              :next-text="'>'"
              :page-class="'page-item c-mr-1 border-unset'"
              prev-link-class="page-link c-mr-1 border-unset"
              next-link-class="page-link c-mr-1 border-unset"
              next-class="next-item"
              prev-class="prev-item"
              page-link-class="page-link c-mr-1 border-unset"
              active-class="active c-mr-1 border-unset"
              :container-class="'pagination  b-pagination'"
          >
          </paginate>
          <!--          <b-pagination-->
          <!--              v-model="pagination.currentPage"-->
          <!--              :total-rows="pagination.totalProducts"-->
          <!--              :per-page="queryParams.limit"-->
          <!--              first-number-->
          <!--              last-number-->
          <!--              class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"-->
          <!--              prev-class="prev-item"-->
          <!--              next-class="next-item"-->
          <!--          >-->
          <!--            <template #prev-text>-->
          <!--              <feather-icon icon="ChevronLeftIcon" size="18"/>-->
          <!--            </template>-->
          <!--            <template #next-text>-->
          <!--              <feather-icon icon="ChevronRightIcon" size="18"/>-->
          <!--            </template>-->
          <!--          </b-pagination>-->
        </div>
      </b-card-text>
    </b-card>

    <CustomNoHeaderModalVue
        v-if="openReceiptModal"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openReceiptModal = false"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
              Receipt Information
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.invoiceNumber') }}:</h1>
          <span>{{ selectedData.document_number }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.customerNumber') }}:</h1>
          <span>{{ selectedData.customer_number }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.invoiceDate') }}:</h1>
          <span>{{ selectedData.invoice_date }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.finalPayDate') }}:</h1>
          <span>{{ selectedData.final_pay_date }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.modeOfPayments') }}:</h1>
          <span>{{ selectedData.mode_of_payments }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">Due Date:</h1>
          <span>{{ selectedData.due_date }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('receiptSendCard.sent') }}:</h1>
          <span v-if="selectedData.receipt_sent">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
              />
            </svg>
          </span>
          <span v-else>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t("receiptSendCard.failureMessage") }}:</h1>
          <span>{{ selectedData.receipt_sending_failure_reason }}</span>
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {mapGetters} from "vuex"
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import useAppConfig from "@core/app-config/useAppConfig";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import accessDenied from "@core/components/permission/accessDenied";
import Paginate from "vuejs-paginate";
import DatePicker from "vue2-datepicker";

export default {
  name: "invoiceExport",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue,
    accessDenied,
    DatePicker,
    paginate: Paginate,
  },
  data() {
    return {
      config: useAppConfig(),
      form: {
        invoice_date_range: {
          start_date: '',
          end_date: '',
        },
        payment_date_range: {
          start_date: '',
          end_date: '',
        },
        due_date_range: {
          start_date: '',
          end_date: ''
        },
      },
      sending: false,
      loading: true,
      messageForm: "",
      categoryType: "",
      openReceiptModal: false,
      selected: false,
      checked: "selected",
      selectedData: {},
      categoryList: [
        {
          'name': 'Golf invoices only',
          param: 'golf_invoice=True',
          key: 'golf_invoice_only',
          label: 'receiptSendCard.golf_invoice_only'
        },
        {
          'name': 'Receipt sent',
          param: 'receipt_sent=True',
          key: 'receipt_sent',
          label: 'receiptSendCard.receipt_sent'
        },
        {
          'name': 'Receipt not sent',
          param: 'receipt_sent=False',
          key: 'receipt_not_sent',
          label: 'receiptSendCard.receipt_not_sent'
        },
        {
          'name': 'Sent receipt successfully',
          param: 'failed_to_send_receipt=False',
          key: 'failed_to_send_receipt',
          label: 'receiptSendCard.failed_to_send_receipt'
        },
        {
          'name': 'Invoice date range',
          param: 'invoice_date_range',
          key: 'invoice_date_range',
          label: 'receiptSendCard.invoiceDateRange'
        },
        {
          'name': 'Payment date range',
          param: 'payment_date_range',
          key: 'payment_date_range',
          label: 'receiptSendCard.paymentDateRange'
        },
        {
          'name': 'Payment date range',
          param: 'due_date_range',
          key: 'due_date_range',
          label: 'receiptSendCard.dueDateRange'
        },
      ],
      selectedInvoice: [],
      selectedCustomer: [],
      invoiceList: [],
      customersList: [],
      emptyCate: "",
      filterType: "",
      items: {results: []},
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        count: 0,
        pages: 1
      },
      fields: [
        {
          key: "checkbox",
          label: "receiptSendCard.invoiceNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "10px",
          },
        },
        {
          key: "invoiceNumber",
          label: "receiptSendCard.invoiceNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "50px",
          },
        },
        {
          key: "customerName",
          label: "receiptSendCard.customerName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
        {
          key: "customerNumber",
          label: "receiptSendCard.customerNumber",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "invoiceDate",
          label: "receiptSendCard.invoiceDate",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "85px",
          },
        },
        {
          key: "finalPayDate",
          label: "receiptSendCard.finalPayDate",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "modeOfPayments",
          label: "receiptSendCard.modeOfPayments",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "sendTime",
          label: "receiptSendCard.sendTime",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
        {
          key: "sent",
          label: "receiptSendCard.sent",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
        //{
        //  key: "message",
        //  label: "receiptSendCard.message",
        //  tdClass: "bTableThStyle",
        //  thStyle: {
        //    padding: "0.72rem 0",
        //    width: "180px",
        //  },
        //},
        {
          key: "is_credit",
          label: "receiptSendCard.isCredit",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "has_been_credited",
          label: "receiptSendCard.hasBeenCredited",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
        {
          key: "action",
          label: "receiptSendCard.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
      ],
      locale: {
        upload: "upload",
        uploadInvoiceTitle: "exportCard.uploadInvoiceTitle",
        alertPermited: "exportCard.alertPermited",
        downloadExcel: "exportCard.downloadExcel",
        messageTitle: "exportCard.receiptTitle",
        receiptFooter: "exportCard.receiptFooter",
        exportLogDetail: "exportCard.exportLogDetails",
        send: "",
      },
      customerNumber: '',
      limit: 20
    };
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == "") {
        return this.swedishLang ? "features" : "features";
      } else return this.getLang == "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    messageList() {
      return this.$t("exportCard.receiptList");
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getReceipt();
    this.fetchCustomer();
  },
  methods: {
    onCategorySelect: function (category) {
      if (['payment_date_range', 'invoice_date_range', 'due_date_range'].includes(category.key) === false) {
        let param = `/?limit=${this.limit}&${category.param}`
        const mode = {URL: param, method: "get"};
        this.$useJwt
            .getReceipt(mode)
            .then((res) => {
              this.loading = false;
              res.data.data.results.map(item => {
                item.checked = false
              })
              this.items = res.data.data;
              if (res.data.data.count > 0) {
                this.pagination.pages = Math.ceil(res.data.data.count / this.limit)
                this.pagination.count = res.data.data.count

              } else {
                this.pagination.pages = 1
                this.pagination.count = 1
              }
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.status === 403) {
                this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
                this.$bvModal.show("modal-access-denied");
              }
            });
        this.categoryType = ''
      } else {
        this.categoryType = category.key
      }

    },
    onChangePaymentDate: function () {
      if (this.form.invoice_date_range || this.form.payment_date_range || this.form.due_date_range) {
        let param = `/?limit=${this.limit}`
        if (this.categoryType === 'payment_date_range' && this.form.payment_date_range) {
          if (this.form.payment_date_range.start_date) {
            param += `&payment_date_start=${this.form.payment_date_range.start_date}`
          }
          if (this.form.payment_date_range.end_date) {
            param += `&payment_date_end=${this.form.payment_date_range.end_date}`
          }
        } else if (this.categoryType === 'due_date_range' && this.form.due_date_range) {
          if (this.form.due_date_range.start_date) {
            param += `&due_date_start=${this.form.due_date_range.start_date}`
          }
          if (this.form.due_date_range.end_date) {
            param += `&due_date_end=${this.form.due_date_range.end_date}`
          }
        } else if (this.categoryType === 'invoice_date_range') {
          if (this.form.invoice_date_range.start_date) {
            param += `&invoice_date_start=${this.form.invoice_date_range.start_date}`
          }
          if (this.form.invoice_date_range.end_date) {
            param += `&invoice_date_end=${this.form.invoice_date_range.end_date}`
          }

        }
        const mode = {URL: param, method: "get"};
        this.$useJwt
            .getReceipt(mode)
            .then((res) => {
              this.loading = false;
              res.data.data.map(item => {
                item.checked = false
              })
              this.items.results = res.data.data;
              this.pagination.pages = res.data.total_pages
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.status === 403) {
                this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
                this.$bvModal.show("modal-access-denied");
              }
            });
      }
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      let params = `limit=${this.limit}&page=${number}`
      if (this.selectedCustomer.length > 0) {
        let customer_number = []
        this.selectedCustomer.map(customer => {
          customer_number.push(customer.customer_number)
        })
        if (customer_number.length) {
          params += `&customer_number=${customer_number.join(',')}`
        }
      }
      this.filterReceipt(params)
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {

      })
    },
    getReceipt(init = null) {
      this.loading = true;
      const mode = init ? init : {URL: `/?limit=${this.limit}`, method: "get"};
      this.$useJwt
          .getReceipt(mode)
          .then((res) => {
            this.loading = false;
            res.data.data.map(item => {
              item.checked = false
            })
            try {
              this.items.results = res.data.data;
              this.pagination.pages = res.data.total_pages
            } catch (e) {
            }

          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 403) {
              this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
              this.$bvModal.show("modal-access-denied");
            }
          });
    },
    filterReceipt(params) {
      this.loading = true;
      const mode = {URL: `/?${params}`, method: "get"};
      this.$useJwt
          .getReceipt(mode)
          .then((res) => {
            this.loading = false;
            res.data.data.map(item => {
              item.checked = false
            })
            this.items.results = res.data.data;
            this.pagination.pages = res.data.total_pages
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    searchRemoteInvoice: debounce(function (loading, search, vm) {
      this.$useJwt
          .getInvoices({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.invoiceList = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchInvoice: function () {
      this.$useJwt
          .getInvoices({URL: "", method: "get"})
          .then((res) => {
            this.invoiceList = res.data.data;
          })
          .catch((err) => {
          });
    },
    setInvoice(item) {
      const uuid = this.selectedInvoice.filter((el) => el.uuid === item.uuid);
      if (uuid.length < 1) {
        this.selectedInvoice.push(item);
      }
      this.emptyCate = "";
    },
    async setCustomer(item) {
      const uuid = this.selectedCustomer.filter((el) => String(el.uuid) === String(item.uuid));
      if (uuid.length < 1) {
        this.selectedCustomer.push(item);
        await this.triggerInvoiceFiltering()

      }
      this.emptyCate = "";
    },
    async triggerInvoiceFiltering() {
      let customer_number = []
      this.selectedCustomer.map(customer => {
        customer_number.push(customer.customer_number)
      })
      let params = customer_number.join(',')
      this.filterReceipt(`customer_number=${params}`)
    },
    removeInvoice(index) {
      this.selectedInvoice.splice(index, 1);
    },
    async removeCustomer(index) {
      this.selectedCustomer.splice(index, 1);
      await this.triggerInvoiceFiltering()
    },
    sendReceipts() {
      this.$refs.formReceipt.validate().then((res) => {
        if (res) {
          let invoices = this.items.results.filter(item => item.checked === true)
          if (invoices.length > 0) {
            const payload = {
              invoice_uuids: [],
              custom_message: this.messageForm,
            };
            const array = [];
            invoices.map((el) => {
              array.push(el.uuid);
            });
            this.sending = true;
            payload.invoice_uuids = array;

            this.$useJwt
                .sendReceipts(payload)
                .then((res) => {
                  this.sending = false;
                  this.$refs.formReceipt.reset();
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.locale_message],
                      "CheckIcon",
                      "success"
                  );
                  this.selectedCustomer = [];
                  this.messageForm = "";
                  this.items.results.map(item => {
                    item.checked = false
                  })
                })
                .catch((err) => {
                  this.sending = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.locale_message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Välj en eller flera fakturor",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    performAllChecked(e) {
      let status = e === 'selected'
      this.items.results.map(item => {
        item.checked = status
      })
    },
    performSingleChecked(e, data) {
    },
    downloadReceipt(uuid) {
      this.$useJwt
          .getReceipt({URL: "/download/" + uuid, method: "get"})
          .then((res) => {
            this.convertAndDownloadPdf(res.data.base64_string, res.data.file_name)
          })
          .catch((err) => {
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    convertAndDownloadPdf(pdf, name) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    searchCustomers(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteCustomers(loading, search, this);

      }
    },
    searchRemoteCustomers: debounce(function (loading, search, vm) {
      this.$useJwt.searchCustomers(`?search=${escape(search)}`)
          .then((res) => {
            this.customersList = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchCustomer: function () {
      this.$useJwt.searchCustomers(``)
          .then((res) => {
            this.customersList = res.data.data;
          })
          .catch((err) => {
          });
    },
    openModal(item) {
      this.selectedData = item;
      this.openReceiptModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>
